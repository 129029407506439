import { Container, H2, H3, Paragraph, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import section7_1 from 'public/img/fof-section7.1.png';
import section7_2 from 'public/img/fof-section7.2.png';

const Section7 = () => {
  return (
    <YStack>
      <Container>
        <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="harvesting">
          {/* Top column (right on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0, justifyContent: 'center' }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Harvest with precision and seamless traceability
            </H2>

            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Harvesting tasks can easily be generated based on what you have in inventory, in-field availability, and
              orders for a given date range. <br />
              <br />
              Your harvesting teams can use the Holo app's harvesting functionality to easily pull up exactly what to
              harvest. To complete a harvesting task, you can simply scan a QR code to record the field as well as
              individual planting information. Once the harvest task is completed, a LOT number is generated, and you
              can print harvest labels with QR codes using our integration with mobile Zebra printers.
            </Paragraph>
          </YStack>

          {/* Bottom column (left on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0 }} justifyContent="center">
            {/* Images */}
            <YStack>
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={section7_1}
                    alt="Harvesting on desktop"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={section7_2}
                    alt="Harvesting on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section7;
