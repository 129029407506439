import { Container, H2, Paragraph, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import saleDashboardMobileImage from 'public/img/sale-dashboard-mobile.png';
import saleDashboardImage from 'public/img/sale-dashboard.png';
import saleNotificationImage from 'public/img/sale-notification.png';

const Section5 = () => {
  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="operations">
      <Container>
        <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14">
          {/* Top column (right on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0, justifyContent: 'center' }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Supercharge productivity for your greenhouse and field operations
            </H2>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              All tasks for your greenhouse and field operations can now be generated automatically based on the
              planting entries in your crop plan. This allows your teams to work autonomously with the tasks available
              directly on their phones. The integration with Zebra desktop and mobile printers enables you to print tray
              labels and planting signs with QR codes directly from the app, providing a fully streamlined QR-based
              workflow, from seeding in trays and transplanting to the field, to harvesting and order packing.
            </Paragraph>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4" style={{ fontStyle: 'italic' }}>
              “The ability to automatically generate tasks for my teams in advance allowed me to take a three-week
              vacation during the height of the season for the first time in my career” - <br />
            </Paragraph>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Anton Burkett, Greenhouse and Field Operations Manager, Norwich Meadows Farm, NY.
            </Paragraph>
          </YStack>
          {/* Bottom column (left on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0 }} justifyContent="center">
            {/* Dashboard images */}
            <YStack>
              <Image
                src={saleNotificationImage}
                alt="Storefront on desktop"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={saleDashboardImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={saleDashboardMobileImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section5;
