import { Circle, Container, H2, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import saleStorefrontCartImage from 'public/img/sale-storefront-cart.png';
import saleStorefrontCheckoutImage from 'public/img/sale-storefront-checkout.png';
import saleStorefrontImage from 'public/img/sale-storefront.png';

const listItems = [
  'Full GAP compliance & LOT traceability',
  'Inventory based on storage and in-field availability',
  'Integration with leading procurement systems',
  'Delivery area & pickup definitions',
  'Powerful discount functionality',
  'Automatic order cut-off times',
];

const Section9 = () => {
  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="sales-platform">
      <Container>
        <YStack space="$8" py="$14" $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }}>
          {/* Top (right on desktop) */}
          <YStack $sm={{ flex: 1, flexBasis: 0 }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              A sales platform with real-time updates based on your storage and in-field availability
            </H2>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Built with the latest tech by world-class e-commerce developers, the sales platform’s inventory is updated
              automatically based on your current storage and in-field scouting, giving you and your customers real-time
              information about your exact availability. LOT numbers are scanned during order packing and included on
              line item labels and invoices. Our powerful LOT search functionality allows you to find all orders
              containing a specific LOT number and view all information related to that specific planting. The platform
              includes an online store designed for wholesale buyers, the ability to input orders and POs manually, and
              we will add direct integration to leading procurement systems during 2025.
            </Paragraph>

            {/* Checkmark list */}
            <YStack mt="$8" space="$4" tag="ul">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>

          <YStack flex={1} $sm={{ flexBasis: 0, justifyContent: 'center' }}>
            {/* Storefront images */}
            <YStack>
              <Image
                src={saleStorefrontImage}
                alt="Storefront on desktop"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={saleStorefrontCartImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={saleStorefrontCheckoutImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section9;
