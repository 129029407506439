import { Circle, Container, H2, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
// import orderOverview from 'public/img/order-overview.png';
import orderPacking from 'public/img/order-packing.gif';

const listItems = [
  'Full LOT number traceability',
  'Update line item quantities and weights',
  'Automate label printing',
  'Automated customer email notification',
];

const Section8 = () => {
  const media = useMedia();

  return (
    <YStack backgroundColor="$accentPale" id="order-packing">
      <Container>
        <YStack gap="$8" py="$14" $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }}>
          {/* Top column (right on desktop because of flex-order) */}
          <YStack
            className={media.md ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $md={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Packing with LOT QR code scanning
            </H2>

            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              The app-based order packing functionality allows you to easily update and confirm line item quantities and
              print labels directly from a phone or tablet. By scanning the QR code printed using the harvesting app,
              you can quickly add one or several LOT numbers to each line item. When the order is packed, a fulfillment
              email is automatically sent to customers, so that they know exactly what they will get.
            </Paragraph>

            <YStack space="$4" mt="$8">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold" color="$pale">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>

          <YStack position="relative" justifyContent="center" $sm={{ flex: 1, flexBasis: 0 }}>
            <Image
              src={orderPacking}
              alt="Order packing on mobile"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            {/* <Image
              src={orderPacking}
              alt="Order overview on desktop"
              style={{
                width: '25vw',
                minWidth: 100,
                maxWidth: 178,
                height: 'auto',
                margin: '-30% auto 0 auto',
              }}
            /> */}
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section8;
