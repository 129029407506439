import { Container, H2, Paragraph, YStack } from '@holo/ui';
import Image from 'next/image';
import taskManagement from 'public/img/task-management.png';

const Section3 = () => {
  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="traceability">
      <Container>
        <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14">
          {/* Top column (right on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0, justifyContent: 'center' }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Automate traceability, food safety and organic reporting
            </H2>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Since we digitized all workflows for seeding, planting, input management, harvesting, inventory, packing,
              and sales, we have complete traceability for all your crops and inputs. In addition to this, our task
              management system helps you seamlessly track all additional requirements for GAP, FSMA, USDA Organic, and
              other certifications. All you have to do is select a date range to refresh your audit reports.
            </Paragraph>
          </YStack>
          {/* Bottom column (left on desktop because of flex-order) */}
          <YStack flex={1} $md={{ flexBasis: 0 }} justifyContent="center">
            {/* Dashboard images */}
            <YStack>
              <Image
                src={taskManagement}
                alt="Task management"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section3;
