import { Container, H2, Paragraph, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import inventory from 'public/img/inventory.gif';

const Section6 = () => {
  const media = useMedia();

  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="inventory">
      <Container>
        <YStack $sm={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14">
          <YStack
            className={media.sm ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $sm={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Combined view of inventory and in-field availability
            </H2>

            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Finally, there is a way to always know exactly what you have in storage and your in-field availability.
              With the Holo app's scouting functionality, your team can easily update weekly harvest availability per
              crop and field, so that you get a real-time overview of your total in-field availability. The in-field
              availability can then be combined with your Holo inventory functionality and sales functionality to
              generate a combined view of what you have in storage, in-field availability, and the quantities that need
              to be fulfilled for a given date range.
            </Paragraph>
          </YStack>

          <YStack className={media.sm ? 'flex-order-1' : 'flex-order-2'} flex={1} $sm={{ flexBasis: 0 }}>
            <Image
              src={inventory}
              priority
              loading="eager"
              alt="Graphic of crop planning"
              style={{
                width: 'auto',
                height: 'auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section6;
